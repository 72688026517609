import * as React from 'react';
import {Menu, MenuItemLink} from 'react-admin';


export const CustomMenu = props => (
    <Menu {...props}>
        <MenuItemLink to={process.env.REACT_APP_BEECARS_URL} primaryText="BeeCars"
                      onClick={() => window.location.href = process.env.REACT_APP_BEECARS_URL}/>
        <MenuItemLink to="/price-ladder" primaryText="Price ladder по комплектациям"/>
        <MenuItemLink to="/market-structure" primaryText="Price ladder по моделям"/>
        <MenuItemLink to="/sales-by-year" primaryText="Продажи по годам"/>
        <MenuItemLink to="/option-sales-by-year" primaryText="Продажи опций по годам"/>
        <MenuItemLink to="/price-and-discounts-history" primaryText="История цен и скидок"/>
    </Menu>
);