import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {SelectorBrandModel} from "./SelectorBrandModel";

export const SelectorPriceHistory = (props) => {
    const {onAddModel, onRemoveModel, onRemoveBrand, models, setTo, setFrom, to, from} = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{width: '100%', marginTop: '30px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <DatePicker
                            views={['year', 'month']}
                            label="Начало периода"
                            minDate={new Date('2015-01-01')}
                            maxDate={new Date()}
                            value={from}
                            onChange={(newValue) => {
                                setFrom(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} size="small" helperText={null}/>}
                            timezone="UTC"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DatePicker
                            views={['year', 'month']}
                            label="Окончание периода"
                            minDate={new Date('2015-01-01')}
                            maxDate={new Date()}
                            value={to}
                            onChange={(newValue) => {
                                setTo(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} size="small" helperText={null}/>}
                        />
                    </Grid>
                    <Grid item xs={8} />
                    <Grid item xs={8}>
                        <SelectorBrandModel onAddModel={onAddModel} onRemoveModel={onRemoveModel} onRemoveBrand={onRemoveBrand} models={models}/>
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    )
}