import * as React from 'react';
import {useState} from 'react';
import Grid from '@mui/material/Grid';
import {useGetList} from "react-admin";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {Typography} from "@mui/material";
import {SelectorModel} from "./SelectorModel";

export const SelectorBrandModel = (props) => {
    const {onAddModel, onRemoveModel, onRemoveBrand, models} = props;
    const [brands, setBrands] = useState([]);

    const {data, total, isLoading, error} = useGetList(
        'brands',
        {
            pagination: {page: 1, perPage: 100},
            sort: {field: 'name', order: 'ASC'},
            filter: {isActive: true, isVirtual: false}
        }
    );

    if (error) {
        return <Typography>Ошибка загрузки марок</Typography>;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" component='h6'>Марки</Typography>
                    </Grid>

                    {
                        isLoading ? <Grid item xs={3}>Загрузка марок</Grid> : data.map((brand) => {
                            return (
                                <Grid item xs={3} key={brand.id}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox onChange={(event) => {
                                            if (event.target.checked) {
                                                setBrands([...brands, brand])
                                            } else {
                                                setBrands(brands.filter(b => b.id !== brand.id))
                                                onRemoveBrand(brand);
                                            }
                                        }}/>} label={brand.name}/>
                                    </FormGroup>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" component='h6'>Модели</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            brands
                                .sort((a, b) => {
                                    if (a.name > b.name) {
                                        return 1;
                                    }
                                    if (b.name > a.name) {
                                        return -1;
                                    }
                                    return 0;
                                })
                                .map(b => {
                                    return (
                                        <Grid container spacing={2} key={b.id + "-models"}>
                                            <Grid item xs={12}><Typography variant="button" display="block">{b.name}</Typography></Grid>
                                            <Grid item xs={12}>
                                                <SelectorModel
                                                    brand={b}
                                                    models={models}
                                                    onAddModel={onAddModel}
                                                    onRemoveModel={(model) => {
                                                        onRemoveModel(model)
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}