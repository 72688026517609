import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {useState} from "react";
import {Typography, LinearProgress} from "@mui/material";
import {SelectorPriceHistory} from "./SelectorPriceHistory";
import CustomPaper from "../../Layout/CustomPaper";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import {useDataProvider} from "react-admin";
import {getCleanHydraId} from "../../utils/HydraUtils";

export const PriceHistory = (props) => {
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const dataProvider = useDataProvider();
    const [models, setModels] = useState([]);

    const handleDownload = () => {
        const lastDate = new Date(to.getFullYear(), to.getMonth() + 1, 0).getDate();

        const data = {
            from: from.getFullYear() + "-" + (from.getMonth() + 1).toString().padStart(2, '0') + '-' + '01',
            to: to.getFullYear() + "-" + (to.getMonth() + 1).toString().padStart(2, '0') + '-' + lastDate,
            //models: options.map(o => parseInt(getCleanHydraId(o.model.id)))
            models: models.map(o => parseInt(getCleanHydraId(o.id)))
        }

        setIsLoading(true);
        dataProvider.create('reports/price-and-discounts', {
            data
        }).then(({data}) => {
            const base64 = JSON.parse(data.result);
            download(base64, 'price-and-discounts-history.xlsx')
        }).finally(() => {
            setIsLoading(false)
        });
    }

    function download(content, fileName) {
        var link = document.createElement("a");
        link.href = content;
        link.download = fileName;
        link.click();
    }

    return (
        <Box sx={{width: '100%'}}>
            <Stack spacing={2}>
                <CustomPaper>
                    <Typography variant="h5" component='h5'>История цен и скидок</Typography>

                    <SelectorPriceHistory
                        setTo={setTo}
                        setFrom={setFrom}
                        to={to}
                        from={from}
                        onAddModel={(model) => {
                            if (Array.isArray(model)) {
                                const currIds = models.map(m => m.id);
                                setModels([...models, ...model.filter(m => !currIds.includes(m.id))])
                            }
                            else {
                                if (models.filter(m => m.id === model.id).length === 0) {
                                    setModels([...models, model])
                                }
                            }
                        }}
                        onRemoveModel={(model) => setModels(models.filter(m => m.id !== model.id))}
                        onRemoveBrand={(brand) => setModels(models.filter(m => m.brand !== brand.id))}
                        models={models}
                    />

                </CustomPaper>
                <CustomPaper>
                    <Box sx={{width: '80%', marginTop: '15px', paddingBottom: '15px'}}>
                        {
                            !isLoading ?
                                <Button startIcon={<SaveIcon/>} onClick={handleDownload}>Сохранить отчет</Button> :
                                <LinearProgress />
                        }
                    </Box>
                </CustomPaper>
            </Stack>
        </Box>
    )
}