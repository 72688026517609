import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDataProvider} from 'react-admin';

import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

export const SelectorModel = (props) => {
    const {brand, models, onAddModel, onRemoveModel} = props;

    const dataProvider = useDataProvider();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider.getList('models', {
            pagination: {page: 1, perPage: 100},
            sort: {field: 'ord', order: 'ASC'},
            filter: {brand: brand.id}
        })
            .then(({data}) => {
                setData(data);
                setIsLoading(false);
            })
            .catch(() => {
                setError(error);
                setIsLoading(false);
            })
    }, [brand]);

    useEffect(() => {
        onAddModel(data);
    }, [data]);

    if (error) {
        return <p>Ошибка загрузки моделей</p>;
    }

    return (
        isLoading ? <Grid item xs={3}>Загрузка моделей</Grid> :

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {
                            data.sort((a, b) => {
                                if (a.isArchive === b.isArchive) {
                                    return 0;
                                }
                                if (a.isArchive === true && b.isArchive === false) {
                                    return 1;
                                }
                                if (a.isArchive === false && b.isArchive === true) {
                                    return -1;
                                }
                            }).map((model) => {
                                return (
                                    <Grid item xs={3} key={model.id}>
                                        <FormGroup>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    onChange={(event) => {
                                                        if (event.target.checked) {
                                                            onAddModel(model)
                                                        } else {
                                                            onRemoveModel(model)
                                                        }
                                                    }}
                                                    checked={models.filter(m => m.id === model.id).length > 0}
                                                />
                                            } label={model.isArchive ? "Архив:" + model.name : model.name}/>
                                        </FormGroup>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>

    )
}