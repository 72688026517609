import { HydraAdmin } from "@api-platform/admin";
import {
    fetchHydra as baseFetchHydra,
    hydraDataProvider as baseHydraDataProvider,
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import {Resource, CustomRoutes} from 'react-admin';
import {Route} from 'react-router-dom';
import {PriceLadder} from "./components/PriceLadder/PriceLadder";
import CustomLayout from "./Layout/CustomLayout";
import theme from "./Layout/theme";
import {MarketStructure} from "./components/PriceLadder/MarketStructure";
import {SalesByYear} from "./components/Sales/SalesByYear";
import {OptionSalesByYear} from "./components/Sales/OptionSalesByYear";
import {PriceHistory} from "./components/PriceHistory/PriceHistory";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        credentials: 'include',
    });

const apiDocumentationParser = async () => {
    try {
        return await parseHydraDocumentation(
            entrypoint,
            { credentials: 'include' }
        );
    } catch (result) {
        const { api, response, status } = result;
        if (status !== 401 || !response) {
            throw result;
        }

        // Prevent infinite loop if the token is expired
        localStorage.removeItem("token");

        return {
            api,
            response,
            status,
        };
    }
};

const dataProvider = baseHydraDataProvider({
    entrypoint: entrypoint,
    httpClient: fetchHydra,
    apiDocumentationParser,
});

export default () => (
    <HydraAdmin
        entrypoint={entrypoint}
        dataProvider={dataProvider}
        layout={CustomLayout}
        theme={theme}
    >
        <Resource name="brands" />
        <Resource name="models" />
        <Resource name="versions" />
        <Resource name="reference-books" />
        <Resource name="reference-book-items" />
        <Resource name="reports/price-ladder" />
        <Resource name="reports/sales/by-year" />
        <Resource name="reports/price-ladder" />
        <CustomRoutes>
            <Route path="/price-ladder" element={<PriceLadder />} />
            <Route path="/market-structure" element={<MarketStructure />} />
            <Route path="/sales-by-year" element={<SalesByYear />} />
            <Route path="/option-sales-by-year" element={<OptionSalesByYear />} />
            <Route path="/price-and-discounts-history" element={<PriceHistory />} />
        </CustomRoutes>
    </HydraAdmin>
);